define("admin/models/imageable", ["exports", "ember-data", "admin/models/shareable"], function (_exports, _emberData, _shareable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shareable.default.extend({
    url: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    taken_at: _emberData.default.attr('string'),
    camera_make: _emberData.default.attr('string'),
    camera_model: _emberData.default.attr('string'),
    white_balance: _emberData.default.attr('string'),
    exposure_time: _emberData.default.attr('string'),
    exposure_bias: _emberData.default.attr('string'),
    version_urls: _emberData.default.attr(),
    processing: _emberData.default.attr('boolean'),
    height: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    missing_gps_info: _emberData.default.attr('boolean'),
    missing_rtk_data: _emberData.default.attr('boolean'),
    is_low_resolution: _emberData.default.attr('boolean'),
    is_blurry_image: _emberData.default.attr('boolean'),
    source_type: _emberData.default.attr('string'),
    gps_latitude: _emberData.default.attr('number'),
    gps_longitude: _emberData.default.attr('number'),
    gps_altitude: _emberData.default.attr('number'),
    distance_from_root: _emberData.default.attr('number'),
    mission: _emberData.default.belongsTo('mission'),
    shot: _emberData.default.belongsTo('shot'),
    takenAt: Ember.computed('taken_at', function () {
      return moment(this.get('taken_at'), "YYYY:MM:DD hh:mm:ss");
    }),
    formattedTakenAt: Ember.computed('takenAt', function () {
      if (this.get('takenAt')) {
        return this.get('takenAt').format("MM/DD/YYYY [at] h:mm a");
      }
      return 'Unknown';
    }),
    autoWhiteBalance: Ember.computed('white_balance', function () {
      if (this.get('white_balance')) {
        return this.get('white_balance') == '0' ? 'Auto white balance' : 'Manual white balance';
      }
      return 'Unknown';
    }),
    dimensions: Ember.computed('width', 'height', function () {
      return 'W: ' + this.get('width') + ', H: ' + this.get('height');
    }),
    isRawAndMissingGpsInfo: Ember.computed('missing_gps_info', 'final', function () {
      return this.get('missing_gps_info') && !this.get('final');
    }),
    downloadUrl: Ember.computed('version_urls.{}', function () {
      return this.get('version_urls.download');
    }),
    thumbnail: Ember.computed('version_urls.{}', 'url', 'processing', function () {
      if (this.get('processing')) {
        return 'assets/images/processing_square_640.jpg';
      } else {
        return this.get('version_urls.square_640');
      }
    }),
    final: Ember.computed('source_type', function () {
      return this.get('source_type') === 'edit';
    })
  });
});