define("admin/models/mission", ["exports", "admin/config/environment", "ember-data", "admin/mixins/invoiceable", "admin/models/shareable", "admin/mixins/payoutable"], function (_exports, _environment, _emberData, _invoiceable, _shareable, _payoutable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _shareable.default.extend(_payoutable.default, _invoiceable.default, {
    ajax: Ember.inject.service(),
    status: _emberData.default.attr('string'),
    pilot_id: _emberData.default.attr('string'),
    capture_group_id: _emberData.default.attr('string'),
    created_on: _emberData.default.attr('string'),
    invoice_needed_timestamp: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),
    pusher_updated_at: _emberData.default.attr('string'),
    instructions: _emberData.default.attr('string'),
    payment_token: _emberData.default.attr('string'),
    internal_notes: _emberData.default.attr('string'),
    internal_production_notes: _emberData.default.attr('string'),
    pilot_script: _emberData.default.attr('string'),
    scheduled_at: _emberData.default.attr('string'),
    scheduled_at_start: _emberData.default.attr('string'),
    scheduled_at_end: _emberData.default.attr('string'),
    admin_scheduled: _emberData.default.attr('boolean'),
    estimated_payout: _emberData.default.attr('string'),
    estimated_pilot_payout: _emberData.default.attr('string'),
    estimated_editor_payout: _emberData.default.attr('string'),
    estimated_analyst_payout: _emberData.default.attr('string'),
    estimated_roof_measurement_payout: _emberData.default.attr('string'),
    estimated_comprehensive_report_purchase_price: _emberData.default.attr('number'),
    comprehensive_report_purchase_subscriber: _emberData.default.attr('boolean'),
    external_assets_url: _emberData.default.attr('string'),
    disable_notifications: _emberData.default.attr('boolean'),
    follow_up_with_pilot_after_accepted: _emberData.default.attr('boolean'),
    require_contact_to_reschedule: _emberData.default.attr('boolean', {
      defaultValue(model) {
        return model.get('package.require_contact_to_reschedule') || false;
      }
    }),
    archives_ready: _emberData.default.attr('boolean'),
    archive_url: _emberData.default.attr('string'),
    database_id: _emberData.default.attr('string'),
    arcgis_feature_layer: _emberData.default.attr('string'),
    wind_inspection: _emberData.default.attr('boolean'),
    // TODO: use 'cents' instead of 'string' for things demoninated in cents
    price: _emberData.default.attr('string'),
    airspace_check_status: _emberData.default.attr('string'),
    accepted: _emberData.default.attr('string'),
    rejection_notes: _emberData.default.attr('string'),
    rejected_by: _emberData.default.attr('string'),
    pilot_comment: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    mission_type: _emberData.default.attr('string'),
    tags: _emberData.default.attr(),
    //array
    mission_tags: _emberData.default.attr(),
    //array
    customer_instructions: _emberData.default.attr('string'),
    salesforce_opportunity_id: _emberData.default.attr('string'),
    archived_at: _emberData.default.attr('string'),
    reference_id: _emberData.default.attr('string'),
    cloud_reqs: _emberData.default.attr('string'),
    scheduling_requirement: _emberData.default.attr('string'),
    number_of_buildings: _emberData.default.attr('number'),
    composing_started: _emberData.default.attr('boolean'),
    require_raw_images: _emberData.default.attr('boolean'),
    auto_ortho_stitching: _emberData.default.attr('boolean'),
    captured_on: _emberData.default.attr('date'),
    first_image_taken_at: _emberData.default.attr('string'),
    last_image_taken_at: _emberData.default.attr('string'),
    turbine_number: _emberData.default.attr('string'),
    pilot: _emberData.default.belongsTo('pilot', {
      async: true
    }),
    client: _emberData.default.belongsTo('client', {
      async: false
    }),
    site_inspection: _emberData.default.belongsTo('site-inspection', {
      async: false
    }),
    onsite_contact: _emberData.default.belongsTo('onsite_contact', {
      async: false
    }),
    scheduling_contact: _emberData.default.belongsTo('scheduling_contact', {
      async: false
    }),
    accountRep: _emberData.default.belongsTo('admin', {
      async: false
    }),
    productionRep: _emberData.default.belongsTo('admin', {
      async: false
    }),
    operationRep: _emberData.default.belongsTo('admin', {
      async: false
    }),
    package: _emberData.default.belongsTo('package', {
      async: false
    }),
    location: _emberData.default.belongsTo('location', {
      async: false
    }),
    credit_card: _emberData.default.belongsTo('credit_card', {
      async: false
    }),
    point_of_interest: _emberData.default.belongsTo('point_of_interest', {
      async: true
    }),
    parent: _emberData.default.belongsTo('mission', {
      inverse: null,
      async: false
    }),
    admin: _emberData.default.belongsTo('admin', {
      async: false
    }),
    mission_flight_app: _emberData.default.belongsTo('mission_flight_app', {
      async: false
    }),
    shots: _emberData.default.hasMany('shot', {
      async: false
    }),
    buckets: _emberData.default.hasMany('buckets', {
      async: false
    }),
    collaborators: _emberData.default.hasMany('collaborator', {
      async: false
    }),
    notified_pilots: _emberData.default.hasMany('notified_pilot', {
      async: true
    }),
    laanc_flights: _emberData.default.hasMany('laanc_flight'),
    laanc_exemptions: _emberData.default.hasMany('airspace'),
    advisories: _emberData.default.hasMany('airspace'),
    organization_portfolio: _emberData.default.belongsTo('organization_portfolio'),
    zendesk_tickets: _emberData.default.hasMany('zendesk_ticket', {
      async: true
    }),
    attachments: _emberData.default.hasMany('attachments', {
      async: true
    }),
    data_files: _emberData.default.hasMany('data_files', {
      async: true
    }),
    images: _emberData.default.hasMany('images', {
      async: true
    }),
    images_markers: _emberData.default.hasMany('image_markers', {
      async: true
    }),
    videos: _emberData.default.hasMany('videos', {
      async: true
    }),
    panoramas: _emberData.default.hasMany('panoramas', {
      async: true
    }),
    ortho_photo_maps: _emberData.default.hasMany('ortho_photo_map', {
      async: true
    }),
    three_d_models: _emberData.default.hasMany('three_d_models', {
      async: true
    }),
    deliverable_work_types: _emberData.default.hasMany("deliverable_work_type"),
    map_features: _emberData.default.hasMany("map_feature", {
      async: false
    }),
    dropships: _emberData.default.hasMany('dropships', {
      async: true
    }),
    catalog_items: _emberData.default.hasMany('catalog_items', {
      async: false
    }),
    asset_inspection: _emberData.default.belongsTo('asset-inspection'),
    // reschedule
    mission_reschedules: _emberData.default.hasMany('mission_reschedule'),
    // reshoots
    parent_id: _emberData.default.attr('string'),
    rejection_reason: _emberData.default.attr('string'),
    reshoot_mission_id: _emberData.default.attr('string'),
    rejection_admin: _emberData.default.belongsTo('admin', {
      async: false
    }),
    partial_reshoot: _emberData.default.belongsTo('partial-reshoot', {
      async: false
    }),
    // on hold
    hold: _emberData.default.belongsTo('hold', {
      async: false
    }),
    is_on_hold: _emberData.default.attr('boolean'),
    ready_for_auto_dispatch: _emberData.default.attr('boolean'),
    //  asset quality rating
    pilot_rating: _emberData.default.belongsTo('rating', {
      async: false
    }),
    mission_payments: _emberData.default.hasMany('mission_payments', {
      async: true
    }),
    waivers: _emberData.default.hasMany('waivers', {
      async: false
    }),
    pointivo_projects: _emberData.default.hasMany('pointivo-project', {
      async: false
    }),
    pointivo_report: _emberData.default.attr('boolean'),
    timeframe_start: _emberData.default.attr('string'),
    timeframe_end: _emberData.default.attr('string'),
    data_capture_within: _emberData.default.attr('string', {
      defaultValue(model) {
        return model.get('package.data_capture_within') || false;
      }
    }),
    data_capture_offset: _emberData.default.attr('string', {
      defaultValue(model) {
        return model.get('package.data_capture_offset') || false;
      }
    }),
    duration: _emberData.default.attr('string'),
    sla_expiry_date: _emberData.default.attr('date'),
    promised_delivery_date: _emberData.default.attr('string'),
    planned_delivery_date: _emberData.default.attr('string'),
    weekend_schedule_filter: _emberData.default.attr('string'),
    pilot_accepted_timestamp: _emberData.default.attr('string'),
    client_upload: _emberData.default.attr('boolean'),
    insights_customer: _emberData.default.attr('boolean'),
    percent_copying_completed: _emberData.default.attr('number'),
    airspace_briefing: _emberData.default.belongsTo('airspace-briefing'),
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),
    legal_entity: _emberData.default.belongsTo('legal-entity', {
      async: false
    }),
    estimated_payouts: _emberData.default.hasMany('estimated-payout', {
      async: false
    }),
    invoice_line_items: _emberData.default.hasMany('invoice-line-item', {
      async: false
    }),
    slaDays: Ember.computed('data_capture_within', function () {
      if (this.get('data_capture_within')) {
        return parseInt(this.get('data_capture_within')) / 24;
      }
      return undefined;
    }),
    slaOffsetDays: Ember.computed('data_capture_offset', function () {
      if (this.get('data_capture_offset')) {
        return parseInt(this.get('data_capture_offset')) / 24;
      }
      return undefined;
    }),
    cancellation: _emberData.default.belongsTo('mission_cancellation', {
      async: false
    }),
    durationInHours: Ember.computed('duration', function () {
      if (this.get('duration')) {
        return parseInt(this.get('duration')) / 60;
      }
      return undefined;
    }),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    pusherSubscribers: [],
    pilot_zendesk_ticket_url: Ember.computed('zendesk_tickets', function () {
      const ticket = this.get('zendesk_tickets').toArray().find(ticket => ticket.get('user_type') === 'pilot');
      if (ticket) {
        return ticket.get('url');
      } else {
        return null;
      }
    }),
    pilot_invitations_dispatch: _emberData.default.belongsTo('pilot_invitations_dispatch', {
      async: false
    }),
    hasMissionFlightApp: Ember.computed('mission_flight_app', function () {
      return !!this.get('mission_flight_app.id');
    }),
    formattedMissionType: Ember.computed('mission_type', function () {
      switch (this.get('mission_type')) {
        case 'creative':
          return 'Creative Mission';
        case 'panorama':
          return 'Panorama Mission';
        case 'client':
          return 'Client Mission';
        default:
          return 'Other';
      }
    }),
    checkAirspace() {
      const store = this.get('store');
      const adapter = store.adapterFor('mission');
      const path = adapter.buildURL('mission');
      return adapter.ajax(`${path}/${this.get('id')}/check_airspace`, 'post').then(responseJson => store.pushPayload(responseJson));
    },
    isNotAirspaceChecked: Ember.computed('airspace_check_status', function () {
      return this.get('airspace_check_status') !== 'complete';
    }),
    isPanoDelivered: Ember.computed('status', function () {
      if (this.get('status') === 'assets_classified') {
        return true;
      } else {
        return false;
      }
    }),
    isScheduled: Ember.computed('scheduled_at_start', 'scheduled_at_end', 'scheduledAtStartSaved', 'scheduledAtEndSaved', function () {
      return this.get('scheduled_at_start') && this.get('scheduled_at_end') && this.get('scheduledAtStartSaved') && this.get('scheduledAtEndSaved');
    }),
    scheduledAtStartSaved: Ember.computed('scheduled_at_start', function () {
      return !this.changedAttributes()['scheduled_at_start'];
    }),
    scheduledAtEndSaved: Ember.computed('scheduled_at_end', function () {
      return !this.changedAttributes()['scheduled_at_end'];
    }),
    completedPanos: Ember.computed('panoramas', function () {
      return this.get('panoramas').filter(item => !item.get('processing'));
    }),
    hasCompletedPano: Ember.computed('completedPanos', function () {
      return this.get('completedPanos').length > 0;
    }),
    assetsCount: Ember.computed('data_files.[]', 'images.[]', 'videos.[]', 'attachments.[]', function () {
      return this.get('data_files.length') + this.get('images.length') + this.get('videos.length') + this.get('attachments.length');
    }),
    readyAttachmentsCount: Ember.computed('buckets.@each.readyAttachmentsCount', function () {
      return this.get('buckets').reduce((count, bucket) => count += bucket.readyAttachmentsCount, 0);
    }),
    readyDataFilesCount: Ember.computed('shots.@each.readyDataFilesCount', function () {
      return this.get('shots').reduce((count, shot) => count += shot.readyDataFilesCount, 0);
    }),
    readyForReview: Ember.computed('hasCompletedPano', 'pilotPanoCompleted', function () {
      return this.get('hasCompletedPano') && !this.get('pilotPanoCompleted');
    }),
    readyImagesCount: Ember.computed('shots.@each.readyImagesCount', function () {
      return this.get('shots').reduce((count, shot) => count += shot.readyImagesCount, 0);
    }),
    readyOrthosCount: Ember.computed('shots.@each.readyOrthosCount', function () {
      return this.get('shots').reduce((count, shot) => count += shot.readyOrthosCount, 0);
    }),
    readyPanosCount: Ember.computed('shots.@each.readyPanosCount', function () {
      return this.get('shots').reduce((count, shot) => count += shot.readyPanosCount, 0);
    }),
    readyVideosCount: Ember.computed('shots.@each.readyVideosCount', function () {
      return this.get('shots').reduce((count, shot) => count += shot.readyVideosCount, 0);
    }),
    totalAssetsCount: Ember.computed('readyDataFilesCount', 'readyImagesCount', 'readyVideosCount', 'readyPanosCount', 'readyOrthosCount', 'readyAttachmentsCount', function () {
      return this.readyDataFilesCount + this.readyImagesCount + this.readyVideosCount + this.readyPanosCount + this.readyOrthosCount + this.readyAttachmentsCount;
    }),
    promotedAssetsCount: Ember.computed(...['shots.@each.promotedDataFilesCount', 'shots.@each.promotedImagesCount', 'shots.@each.promotedVideosCount', 'shots.@each.promotedPanosCount', 'shots.@each.promotedOrthosCount', 'buckets.@each.promotedAttachmentsCount'], function () {
      let assets = this.get('shots').reduce((count, shot) => count += shot.promotedImagesCount + shot.promotedVideosCount + shot.promotedPanosCount + shot.promotedOrthosCount + shot.promotedDataFilesCount, 0);
      return assets += this.get('buckets').reduce((count, bucket) => count += bucket.promotedAttachmentsCount, 0);
    }),
    shotAssetsCount: Ember.computed('readyDataFilesCount', 'readyImagesCount', 'readyOrthosCount', 'readyPanosCount', 'readyVideosCount', function () {
      return this.readyDataFilesCount + this.readyImagesCount + this.readyOrthosCount + this.readyPanosCount + this.readyVideosCount;
    }),
    imagePagingMetas: Ember.computed('shots.@each.imagePagingMeta', function () {
      return this.shots.mapBy('imagePagingMeta');
    }),
    assetsWithQCIssuesCount: Ember.computed('imagePagingMetas.@each.quality_control_issues_count', function () {
      return this.imagePagingMetas.reduce((count, meta) => count += meta?.quality_control_issues_count || 0, 0);
    }),
    missingGPSInfoAssetsCount: Ember.computed('imagePagingMetas.@each.missing_gps_info_count', function () {
      return this.imagePagingMetas.reduce((count, meta) => count += meta?.missing_gps_info_count || 0, 0);
    }),
    lowResolutionAssetsCount: Ember.computed('imagePagingMetas.@each.low_resolution_count', function () {
      return this.imagePagingMetas.reduce((count, meta) => count += meta?.low_resolution_count || 0, 0);
    }),
    blurrinessAssetsCount: Ember.computed('imagePagingMetas.@each.blurriness_info_count', function () {
      return this.imagePagingMetas.reduce((count, meta) => count += meta?.blurriness_info_count || 0, 0);
    }),
    missingRTKDataAssetsCount: Ember.computed('imagePagingMetas.@each.missing_rtk_data_count', function () {
      return this.imagePagingMetas.reduce((count, meta) => count += meta?.missing_rtk_data_count || 0, 0);
    }),
    canInvitePilot: Ember.computed('status', function () {
      return ['confirmed', 'pilots_notified'].includes(this.get('status'));
    }),
    isShareable: Ember.computed('status', function () {
      return ['awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    isInProgress: Ember.computed('status', function () {
      const inProgressStatuses = ['pilot_accepted', 'flight_complete', 'assets_uploaded', 'processing_shots', 'assets_classified', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'];
      return inProgressStatuses.includes(this.get('status'));
    }),
    pilotPanoCompleted: Ember.computed('status', function () {
      return ['in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    assetsClassified: Ember.computed('status', function () {
      return ['assets_classified', 'in_production', 'awaiting_payment', 'invoice_needed', 'invoiced', 'complete'].includes(this.get('status'));
    }),
    isOnHold: Ember.computed('is_on_hold', function () {
      return this.get('hold') != null || this.get('is_on_hold');
    }),
    hasPartialReshoot: Ember.computed('partial_reshoot', function () {
      return this.get('partial_reshoot') != null;
    }),
    isReshoot: Ember.computed('parent_id', function () {
      return this.get('parent_id') != null;
    }),
    isFree: Ember.computed('price', function () {
      return parseInt(this.get('price')) === 0;
    }),
    isCallToSchedule: Ember.computed('scheduling_contact', 'scheduling_requirement', function () {
      return this.get('scheduling_requirement') === 'call_to_schedule' && this.get('scheduling_contact') != null;
    }),
    hasReshoot: Ember.computed('status', 'reshoot_mission_id', function () {
      return this.get('status') === 'rejected' && this.get('reshoot_mission_id') != null;
    }),
    needsEstimatedPayout: Ember.computed('estimated_pilot_payout', 'mission_type', function () {
      return this.get('mission_type') !== 'training' && this.get('estimated_pilot_payout') === '0';
    }),
    shotsWithProcesses: Ember.computed('shots.length', function () {
      return this.get('shots').filter(shot => shot.get('hasPostProcess'));
    }),
    invoicedPrice: Ember.computed('price', 'status', 'credit_card', function () {
      if (['invoiced', 'complete'].includes(this.get('status'))) {
        if (this.get('credit_card') == null) {
          return this.get('price');
        }
      }
      return null;
    }),
    invoicedCurrency: Ember.computed('currency', 'status', 'credit_card', function () {
      if (['invoiced', 'complete'].includes(this.get('status'))) {
        if (this.get('credit_card') == null) {
          return this.get('currency');
        }
      }
      return null;
    }),
    showRegeneratePointivoReport: Ember.computed('attachments', 'buckets', function () {
      let pointivoBucket = this.get('buckets').filter(bucket => bucket.get('bucket_type.slug') === 'pointivo_report');
      if (Ember.isPresent(pointivoBucket)) {
        pointivoBucket = pointivoBucket.firstObject;
        const hasPointivoBucket = Ember.isPresent(pointivoBucket);
        const appropriateStatuses = ["in_production", "invoice_needed", "complete", "assets_classified"];
        return appropriateStatuses.indexOf(this.get('status')) >= 0 && hasPointivoBucket;
      }
      return null;
    }),
    loadCapacityEstimations(day, sessionHeaders) {
      const url = _environment.default.api.host + "/v1/admin/missions/" + this.get('id') + "/capacity_estimations";
      return this.get('ajax').request(url, {
        type: 'POST',
        dataType: 'json',
        headers: sessionHeaders,
        data: {
          day,
          timeslot_duration: this.get('duration')
        }
      }).then(response => {
        return response.data.attributes.availability_slots;
      }, error => {
        if (error.errors) {
          const message = error.errors[0].detail;
          return console.error(message);
        }
      });
    },
    loadActivityLogs() {
      return this.get('store').query('activity-log', {
        missionId: this.get('id')
      }).then(activityLogs => {
        return this.set('activity_logs', activityLogs);
      }, error => {
        return alert(error);
        // console.log 'error retrieving activity: ' + message
      });
    },

    loadPayments() {
      return this.get('store').query('mission_payment', {
        missionId: this.get('id')
      }).then(response => {
        return this.set('mission_payments', response);
      });
    },
    loadImageMarkers() {
      return this.get('store').query('image-marker', {
        missionId: this.get('id')
      }).then(images => {
        return this.set('image_markers', images);
      }, error => {
        if (error.errors) {
          const message = error.errors[0].detail;
          return alert(message);
        }
        // console.log 'error retrieving images markers: ' + error
      });
    },

    loadPanoramas() {
      return this.get('store').query('panorama', {
        missionId: this.get('id')
      }).then(panoramas => {
        return this.set('panoramas', panoramas);
      });
    },
    loadVideos() {
      return this.get('store').query('video', {
        missionId: this.get('id')
      }).then(videos => {
        return this.set('videos', videos);
      });
    },
    loadDataFiles() {
      return this.get('store').query('data_file', {
        missionId: this.get('id')
      }).then(data_files => {
        return this.set('data_files', data_files);
      });
    },
    loadOrthoPhotoMaps() {
      return this.get('store').query('ortho_photo_map', {
        missionId: this.get('id')
      }).then(ortho_photo_maps => {
        return this.set('ortho_photo_maps', ortho_photo_maps);
      });
    },
    loadThreeDModels() {
      return this.get('store').query('three_d_model', {
        missionId: this.get('id')
      }).then(three_d_models => {
        return this.set('three_d_models', three_d_models);
      });
    },
    standardLineItems: Ember.computed('invoice_line_items.{length,@each.currency,@each.totalAmount}', function () {
      return this.get('invoice_line_items').filter(invoiceLineItem => !invoiceLineItem.get('work_item.map_feature')).sortBy('created_on');
    }),
    groupedMapFeatureLineItems: Ember.computed('invoice_line_items.{length,@each.currency,@each.amount}', function () {
      const mapFeatureGroupedItems = new Map();
      this.get('invoice_line_items').filter(invoiceLineItem => invoiceLineItem.get('work_item.map_feature')).forEach(item => {
        const key = item.work_item.map_feature.id;
        const groupedCost = mapFeatureGroupedItems.get(key);
        if (groupedCost) {
          return groupedCost.totalAmount += item.amount;
        } else {
          const groupedItem = Ember.Object.create({
            map_feature: item.work_item.map_feature,
            feature_details: item.work_item.calculator_debug_info,
            totalAmount: item.amount,
            currency: item.currency
          });
          return mapFeatureGroupedItems.set(key, groupedItem);
        }
      });
      return Array.from(mapFeatureGroupedItems.values());
    }),
    loadDropships() {
      return this.get('store').query('dropship', {
        mission_id: this.get('id')
      }).then(dropships => {
        return this.set('dropships', dropships);
      });
    },
    regenerateZip() {
      const store = this.get('store');
      const adapter = store.adapterFor('mission');
      const path = adapter.buildURL('mission');
      return adapter.ajax(`${path}/${this.get('id')}/regenerate_archives`, 'post').then(() => {
        this.set('archivesGenerating', true);
        return alert("The zip file is now generating");
      });
    },
    cloud_reqs_verbose: Ember.computed('cloud_reqs', function () {
      const valueWithDashes = this.get('cloud_reqs') || 'less_than_100';
      return valueWithDashes.replace(/_/g, ' ').replace('less than', 'Up to') + '% cloudy';
    }),
    slaTimeToScheduleInDays: Ember.computed('data_capture_within', function () {
      try {
        return parseInt(this.get('data_capture_within')) / 24;
      } catch (error) {
        return 0;
      }
    }),
    slaOffsetTimeToScheduleInDays: Ember.computed('data_capture_offset', function () {
      try {
        return parseInt(this.get('data_capture_offset')) / 24;
      } catch (error) {
        return 0;
      }
    }),
    missionStatusType: Ember.computed('status', 'completed', 'point_of_interest', function () {
      if (this.get('mission_type') === 'creative') {
        return 'creative_mission';
      }
      if (this.get('point_of_interest')) {
        if (this.get('completed')) {
          if (this.get('point_of_interest.property_type') === 'commercial') {
            return 'pano_commercial_completed';
          } else {
            return 'pano_residential_completed';
          }
        } else {
          if (this.get('point_of_interest.property_type') === 'commercial') {
            return 'pano_commercial_active';
          } else {
            return 'pano_residential_active';
          }
        }
      } else if (this.get('status') === 'pilots_notified') {
        return 'client_mission_available';
      } else {
        return 'client_mission';
      }
    }),
    // eslint-disable-next-line ember/no-observers
    schedulingContactCreator: Ember.observer('scheduling_requirement', function () {
      if (this.get('scheduling_requirement') === 'call_to_schedule' && !this.get('scheduling_contact')) {
        return this.get('store').createRecord('scheduling_contact', {
          mission: this
        });
      }
    }),
    skipInProduction: Ember.computed('deliverable_work_types.length', function () {
      return this.get('deliverable_work_types.length') === 0;
    }),
    arcgisLink: Ember.computed('client.esri_account.baseArcgisUrl', 'arcgis_feature_layer', function () {
      if (this.get('arcgis_feature_layer')) {
        return `${this.get('client.esri_account.baseArcgisUrl')}/home/item.html?id=${this.get('arcgis_feature_layer')}`;
      }
      return null;
    }),
    promoteAssets() {
      const adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.promoteAssets(this);
    },
    pointivoProject: Ember.computed('pointivo_projects.[]', function () {
      return this.get('pointivo_projects').firstObject;
    }),
    pointivoResourceStatus: Ember.computed('pointivo_projects.[]', function () {
      return this.get('pointivoProject.resource_status');
    }),
    clientUploadProcessingName: Ember.computed('deliverable_work_types.[]', function () {
      if (this.get('deliverable_work_types').findBy('name', 'Roof Measurements')) {
        return 'Roof Measurements';
      } else if (this.get('auto_ortho_stitching')) {
        return 'Ortho stitching';
      } else {
        return 'Not Set';
      }
    }),
    windDeliverableWorkTypes: Ember.computed('deliverable_work_types.[]', function () {
      return this.get('deliverable_work_types').filter(d => d.process_type === 'wind');
    }),
    windInspectionTypes: Ember.computed('windDeliverableWorkTypes.[]', function () {
      return [...new Set(this.get('windDeliverableWorkTypes').mapBy('inspection_type'))];
    }),
    isCopiedMission: Ember.computed('mission_tags.[]', function () {
      return this.get('mission_tags')?.some(tag => tag.startsWith('copy:'));
    }),
    copiedMissionNumber: Ember.computed('mission_tags.[]', function () {
      const tag = this.get('mission_tags')?.find(tag => tag.startsWith('copy:'));
      if (tag) {
        const parts = tag.split(':');
        let copy_number;
        if (parts.length == 2) {
          copy_number = parts[1];
        } else {
          copy_number = parts[2];
        }
        return copy_number.toUpperCase();
      }
      return null;
    }),
    copiedMissionSrc: Ember.computed('mission_tags.[]', function () {
      const tag = this.get('mission_tags')?.find(tag => tag.startsWith('copy:'));
      if (tag) {
        const parts = tag.split(':');
        let source;
        if (parts.length == 2) {
          return null;
        } else {
          source = parts[1];
        }
        return source;
      }
      return null;
    }),
    copiedMissionUrl: Ember.computed('mission_tags.[]', function () {
      const tag = this.get('mission_tags')?.find(tag => tag.startsWith('copy:'));
      if (tag) {
        const parts = tag.split(':');
        let source;
        let copyNumber;
        if (parts.length == 2) {
          return null;

          // only create url if the tag is copy:src_env:dbm
          //
          // older tags will only be copy:dbm
        } else {
          source = parts[1];
          copyNumber = parts[2];
        }
        let urlPrefix;
        if (source === "production") {
          urlPrefix = "https://admin.dronebase.com";
        } else if (source == "staging") {
          urlPrefix = "https://admin.staging.dronebase.com";
        } else {
          urlPrefix = `https://dronebase-admin-${source.toLowerCase()}.herokuapp.com`;
        }
        return `${urlPrefix}/missions/${copyNumber.toUpperCase()}/edit`;
      }
      return null;
    }),
    copyingIsInProgress: Ember.computed('percent_copying_completed', function () {
      if (this.get('isCopiedMission')) {
        return this.get('percent_copying_completed') < 100;
      }
      return null;
    })
  });
});